import React, { useContext, useRef } from "react";
import { useHistory } from "react-router";
import logo from "../../img/apple-touch-icon.png";
import MicrostudyApi from "../../api/MicrostudyApi";
import UserContext from "../../store/userContext";
import { toast } from "react-toastify";

const SignupOtpVerification = () => {
  const userContext = useContext(UserContext);
  const otpInputRef = useRef();
  const history = useHistory();
  const email = userContext.userEmail;

  const signUpVerifivationHandler = async (e) => {
    e.preventDefault();
    const enterOtp = otpInputRef.current.value;
    const otp = parseInt(enterOtp);
    try {
      const response = await MicrostudyApi.post(
        "/auth/user/verify-email",
        {
          email,
          otp,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 201) {
        toast("User Verified successfully");
        history.push("/auth/signin");
      } else {
        toast("invalid credential!");
      }
    } catch (error) {
      toast(error.message);
    }
  };
  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img alt="" className="mx-auto h-16 w-auto" src={logo} />
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Verify it's you
            </h2>
            <p className="mt-2 text-center text-base text-gray-700">
              We sent a verification code to your email. Enter the code from the
              email in the field below.
            </p>
            <p className="mt-2 text-center text-base text-gray-700">
              Please check email in All mail section.
            </p>
          </div>
          <form className="mt-8 space-y-6" onSubmit={signUpVerifivationHandler}>
            <div className="mt-2">
              <label htmlFor="email" className=" text-xl font-semibold">
                Email address
              </label>
              <p className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-base">
                {email}
              </p>
            </div>
            <div className="rounded-md shadow-sm ">
              <span className="my-4 py-8 text-lg">
                6-digit code<span className="text-red-500 text-lg">*</span>
              </span>
              <input
                id="otp"
                name="otp"
                type="number"
                ref={otpInputRef}
                required
                className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Enter OTP"
              />
            </div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
            >
              Verify Code
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default SignupOtpVerification;
