/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/favicon32.png";
export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 z-50 bg-gray-100 sticky  w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link
                exact
                to="/"
                className=" flex  cursor-pointer items-center text-3xl px-3 py-2 font-bold rounded-md active:bg-green-500"
              >
                <img alt="" className="px-2" src={logo} />
                Microstudy
              </Link>
            </div>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="text-black fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-gray-50 lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block rounded shadow-lg" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <Link
                  exact="true"
                  className=" text-gray-700  text-lg font-bold  px-4 py-2 rounded  outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"
                  to="/"
                >
                  Back to Home
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
