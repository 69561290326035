import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";
//new imports
import Start from "./components/Start";
import Question from "./components/Question";
import End from "./components/End";

let interval;

const TestSeries = () => {
  const { id } = useParams();
  const authContext = useContext(AuthContext);
  ///quiz data
  const [quizData, setQuizData] = useState([]);

  const [step, setStep] = useState(1);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    getTestSeries();
  }, [id]);

  useEffect(() => {
    if (step === 3) {
      clearInterval(interval);
    }
  }, [step]);

  const getTestSeries = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(`/user/getallquestions/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setQuizData(response.data["getAllQuestions"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.message);
    }
  };

  const quizStartHandler = () => {
    setStep(2);
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const resetClickHandler = () => {
    setActiveQuestion(0);
    setAnswers([]);
    setStep(2);
    setTime(0);
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  return (
    <div className="max-w-7xl mx-auto">
      <div className="min-h-full flex items-center justify-center">
        <div className="w-full">
          {step === 1 && (
            <Start
              onQuizStart={() => {
                quizStartHandler();
              }}
              data={quizData}
            />
          )}
          {step === 2 && (
            <Question
              data={quizData[activeQuestion]}
              onAnswerUpdate={setAnswers}
              numberOfQuestions={quizData.length}
              activeQuestion={activeQuestion}
              onSetActiveQuestion={setActiveQuestion}
              onSetStep={setStep}
            />
          )}
          {step === 3 && (
            <>
              <End
                results={answers}
                data={quizData}
                onReset={resetClickHandler}
                onAnswersCheck={() => setShowModal(true)}
                time={time}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestSeries;
