import React, { useEffect, useState } from "react";

import { formatTime } from "../utils";

const End = ({ results, data, time }) => {
  const [correctAnswers, setCorrectAnswers] = useState(0);

  useEffect(() => {
    let correct = 0;
    results?.map((result, index) => {
      if (result.a === data[index].answers) {
        correct++;
      }
    });
    setCorrectAnswers(correct);
  }, []);

  return (
    <>
      <div className="container">
        <div className="mt-5  md:col-span-2">
          <div className="shadow-md overflow-hidden sm:rounded-md">
            <div className=" w-full py-12 px-6 flex justify-center items-center ">
              <div className="max-w-full h-auto flex-col justify-center bg-white  rounded-lg border-2 border-indigo-500 p-5">
                <div className=" p-2 space-y-2">
                  <div className="text-gray-800 justify-center items-center flex">
                    <span className="text-left font-semibold text-xl">
                      Your Result :
                    </span>
                    <span className="text-right  justify-end font-semibold items-end mx-2">
                      {correctAnswers}
                      <span className="text-indigo-600 font-medium px-1">
                        out of
                      </span>
                      {data.length}
                    </span>
                  </div>
                  <div className="text-gray-800 dark:text-gray-100 ">
                    <span className="text-left font-semibold text-xl">
                      Percentage :
                    </span>
                    <span className="text-right font-medium  justify-end items-end mx-5">
                      {Math.floor((correctAnswers / data.length) * 100)}%
                    </span>
                  </div>
                  <div className="text-gray-800 dark:text-gray-100 ">
                    <span className="text-left font-semibold text-xl">
                      Time Taken :
                    </span>
                    <span className="text-right font-medium justify-end items-end mx-5">
                      {formatTime(time)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-md bg-gray-100 shadow-md p-5 border-gray-900">
              <p className="text-gray-900  text-2xl font-semibold flex justify-center items-center">
                Preview test
              </p>

              <div className="m-4">
                {results?.map((result, i) => (
                  <div
                    key={i}
                    className="my-4 border-gray-900 rounded-md border-2 p-4 space-y-2"
                  >
                    <h1 className="text-red-500 capitalize font-bold text-lg">
                      {result.q}
                    </h1>

                    <p
                      className={
                        result.a === data[i].answers
                          ? "text-green-500 font-medium text-base"
                          : "text-red-500 font-medium text-base"
                      }
                    >
                      <span className="text-gray-900 font-semibold">
                        Your answer :
                      </span>{" "}
                      {result.a}
                    </p>

                    <p className="text-base text-gray-900">
                      <span className="text-gray-900 capitalize font-semibold">
                        Correct answer:
                      </span>{" "}
                      {data[i].answers}
                    </p>
                    <p className="text-gray-900 text-base capitalize">
                      <span className="text-gray-900  capitalize font-semibold">
                        description:{" "}
                      </span>
                      {data[i].description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default End;
