import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import ExamCategory from "./ExamCategory";
import AuthContext from "../../store/authContext";
import { toast } from "react-toastify";

const ExamList = () => {
  const authContext = useContext(AuthContext);
  const isSignedin = authContext.isAuthenticated;
  const [examData, setExamData] = useState([]);
  useEffect(() => {
    if (isSignedin) {
      getExamList();
    }
  }, []);
  const getExamList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(`/user/enrolled-courses-fetch`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setExamData(response.data["AllEnrolledCourse"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <React.StrictMode>
      <div>
        {examData?.map((AllEnrolledCourse) => (
          <ExamCategory
            key={AllEnrolledCourse["examid"]}
            name={AllEnrolledCourse["name"]}
            id={AllEnrolledCourse["examid"]}
          />
        ))}
      </div>
    </React.StrictMode>
  );
};
export default ExamList;
