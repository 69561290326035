import React, { useState } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";

const Contact = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");
  const [allEntry, setallEntry] = useState([]);
  const submitForm = (e) => {
    e.preventDefault();
    const newEntry = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };
    setallEntry([...allEntry, newEntry]);
    emailjs
      .sendForm(
        "service_microstudy",
        "template_m7qpzd8",
        e.target,
        "user_j5YapTyaveDmgQIc238lT"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast("Message sent successfully!");
          }
        },
        (error) => {
          toast(error.text);
        }
      );
    setname("");
    setemail("");
    setphone("");
    setmessage("");
  };

  return (
    <div id="Contact" className="mx-auto container py-6 px-6 md:px-20">
      <div className="flex justify-center items-center">
        <h1 className="text-5xl  text-center font-bold px-3 text-gray-900">
          We're Here
        </h1>
      </div>
      <div className="w-full  grid grid-cols-1 md:grid-cols-2 md:px-10 px-4   py-10  bg-white text-gray-900">
        <div className="flex flex-col justify-between">
          <div className="px-4">
            <h2 className="text-4xl lg:text-5xl font-bold leading-tight">
              Lets talk about everything!
            </h2>
            <div className="  text-gray-700 mt-8">
              <span className="text-center items-center">
                <a
                  target="_blank"
                  href="tel:+917992257628"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg items-center hover:underline font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  +91 799 225 7628
                </a>
                <a
                  target="_blank"
                  href="tel:+918709362272"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg items-center hover:underline font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    viewBox="0 0 20 20"
                    fill="#4F46E5"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  +91 870 936 2272
                </a>
              </span>
              <span className=" ">
                <a
                  target="_blank"
                  href="mailto:micropublications360@gmail.com"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg hover:underline  items-center font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="#4F46E5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  micropublications360@gmail.com
                </a>
              </span>
            </div>
          </div>
        </div>
        <form onSubmit={submitForm}>
          <div>
            <label className="text-lg text-indigo-600 font-bold">
              {" "}
              Full Name
            </label>
            <input
              className="w-full  bg-gray-50 text-gray-900  p-3 rounded-lg  border-gray-400  placeholder-gray-700  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              type="text"
              name="user_name"
              required
              autoComplete="off"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label className=" text-lg text-indigo-600 font-bold">Email</label>
            <input
              className="w-full  bg-gray-50 text-gray-900  p-3 rounded-lg  border-gray-400  placeholder-gray-700  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              type="email"
              name="user_email"
              autoComplete="off"
              required
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label className=" text-lg text-indigo-600 font-bold">Phone</label>
            <input
              className="w-full  bg-gray-50 text-gray-900  p-3 rounded-lg  border-gray-400  placeholder-gray-700  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              type="number"
              name="user_phone"
              autoComplete="off"
              required
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <label className="text-lg text-indigo-600 font-bold">Message</label>
            <textarea
              className="w-full h-24 bg-gray-50 text-gray-900  p-3 rounded-lg  border-gray-400  placeholder-gray-700  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              autoComplete="off"
              name="user_message"
              required
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            ></textarea>
          </div>
          <div className="mt-4">
            <button
              type="submit"
              // onClick={notify}
              className=" text-2xl font-semibold  bg-indigo-400 text-gray-900 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Contact;
