import React from "react";
import aboutimg from "../img/about.jpg";
const About = () => {
  return (
    <>
      <div id="about" className="mx-auto container py-6 px-6 md:px-20">
        <div className="flex flex-col lg:flex-row justify-between gap-8  py-10 lg:px-10 px-4  rounded-lg bg-indigo-300">
          <div className="w-full lg:w-5/12 flex flex-col justify-center ">
            <h1 className="text-4xl lg:text-4xl font-bold  text-black py-4">
              About us
            </h1>
            <div className="my-5">
              <p className="font-medium text-lg  my-2 text-black ">
                Microstudy is an online educational service provider which
                contains various competitive examinations related study
                materials which helps students (subscribers) to prepare for
                those examinations (in a paid manner).
              </p>
              <p className="font-medium text-lg  text-black ">
                Users can subscribe its website or android application (which is
                available on play store) in order to use its services. Study
                materials are prepared by highly qualified faculties which are
                completely analyzed and based according to their syllabus and
                previous year asked questions and are considered as a suggestion
                for the upcoming examinations.
              </p>
            </div>
          </div>
          <div className="lg:w-8/12 lg:p-10 ">
            <img
              className="w-full h-full rounded-lg"
              src={aboutimg}
              alt="A group of People"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
