import React from "react";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import logo from "../../img/apple-touch-icon.png";
import MicrostudyApi from "../../api/MicrostudyApi";
import { toast } from "react-toastify";

const UpdatePassword = () => {
  const emailInputRef = useRef();
  const otpInputRef = useRef();
  const passwordInputRef = useRef();
  const confirmPasswordInputRef = useRef();
  const history = useHistory();

  const signUpHandler = async (e) => {
    e.preventDefault();

    const email = emailInputRef.current.value;
    const password = passwordInputRef.current.value;
    const confirmpassword = confirmPasswordInputRef.current.value;
    const otp = otpInputRef.current.value;
    try {
      const response = await MicrostudyApi.post(
        "/auth/user/psw-reset",
        {
          email,
          password,
          confirmpassword,
          otp,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 201) {
        toast("password Update successfully");
        history.push("/auth/signin");
      } else {
        toast("invalid credential!");
      }
    } catch (error) {
      toast(error.response.message);
    }
  };
  return (
    <>
      <div className="min-h-screen px-6  flex items-center justify-center ">
        <div className="max-w-md w-full">
          <div>
            <img alt="" className="mx-auto h-16 w-auto" src={logo} />
            <h2 className="mt-6  text-center text-3xl font-bold text-gray-900">
              Update Password
            </h2>
          </div>
          <form className=" space-y-6" onSubmit={signUpHandler}>
            <div className="rounded-md shadow-sm ">
              <div className="mt-2">
                <label htmlFor="email" className=" text-lg font-semibold">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  ref={emailInputRef}
                  required
                  className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email Address"
                />
              </div>

              <div className="mt-2">
                <label htmlFor="password" className="text-lg font-semibold">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  ref={passwordInputRef}
                  required
                  className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder=" Enter Password"
                />
              </div>
              <div className="mt-2">
                <label htmlFor="password" className="text-lg font-semibold">
                  Confirm Password
                </label>
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="confirmpassword"
                  ref={confirmPasswordInputRef}
                  required
                  className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder=" Enter Password"
                />
              </div>
              <div className="mt-2">
                <label htmlFor="phone" className=" text-lg font-semibold">
                  Otp
                </label>
                <input
                  id="otp"
                  name="otp"
                  type="number"
                  ref={otpInputRef}
                  required
                  className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Enter OTP"
                />
              </div>
            </div>

            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
            >
              Update Password
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default UpdatePassword;
