import React from "react";
import examtable from "../../../img/ExamTable.jpg";
const crrTime = new Date().toLocaleDateString();
const Start = ({ onQuizStart, data }) => {
  return (
    <>
      <div className="my-2 mx-5 md:col-span-2">
        <div className="py-2  rounded-md ">
          <h1 className="flex justify-center items-cenetr lg:items-end lg:justify-end text-2xl font-bold">
            Date : {crrTime}
          </h1>
          {data.length ? (
            <div className="my-5 sm:rounded-md sm:overflow-hidden">
              <h1 className="mx-auto flex justify-center my-3 text-indigo-600 text-2xl font-bold">
                Good luck !
              </h1>
              <button
                className="flex mx-auto my-4 justify-center py-2 px-8 border border-transparent shadow-md text-lg font-semibold rounded-md text-gray-900 bg-purple-400 hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                onClick={() => {
                  onQuizStart();
                }}
              >
                Start Tour Test
              </button>
            </div>
          ) : (
            <div className="text-red-600 text-center font-semibold text-lg lg:text-4xl my-5">
              <h1>Please wait till test scheduled date.</h1>
            </div>
          )}
        </div>
      </div>
      <div className="flex-col flex w-full h-full ">
        <img src={examtable} alt="Exam table" />
      </div>
    </>
  );
};

export default Start;
