import React from "react";
import { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../../img/apple-touch-icon.png";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import UserContext from "../../store/userContext";
import { toast } from "react-toastify";

const Signin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [allCredential, setAllCredential] = useState([]);

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);

  const signinHandler = async (e) => {
    e.preventDefault();
    const newCredential = { email, password };
    setAllCredential([...allCredential, newCredential]);

    try {
      const response = await MicrostudyApi.post(
        "/auth/user/signin",
        {
          email,
          password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      const userName = response.data.name;
      let expirationTime = new Date();
      expirationTime.setDate(expirationTime.getDate() + 1);
      if (response.status === 201) {
        authContext.Signin(
          response.data.token,
          response.data.refreshToken,
          expirationTime.toISOString()
        );
        userContext.setUserEmail(email);
        userContext.setUserName(userName);
        history.replace("/");
      } else {
        toast(response.data.message);
        setEmail("");
        setPassword("");
      }
    } catch (error) {
      toast(
        error.response ? error.response.data.message : "server not responding"
      );

      if (error.response.status === 404) {
        try {
          const response = await MicrostudyApi.post(
            "/auth/user/reverify",
            {
              email,
            },
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (response.status === 201) {
            userContext.setUserEmail(email);
            history.replace("/auth/signup/otp-verification");
          } else {
            toast("unauthorized user found!");
          }
        } catch (error) {
          toast(error.response.message);
          setEmail("");
          setPassword("");
        }
      }
    }
    setEmail("");
    setPassword("");
  };
  return (
    <>
      <div className="min-h-screen px-6  flex items-center justify-center ">
        <div className="max-w-md w-full">
          <div>
            <img alt="" className="mx-auto h-16 w-auto" src={logo} />
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Sign in to your account
            </h2>
            <div className="mt-2 text-center text-sm text-gray-600">
              <span className="px-1 text-base font-semibold">
                Don't have an account?
              </span>
              <Link
                exact="true"
                to="/auth/signup"
                className="font-semibold text-red-500 text-xl hover:text-indigo-500">
                Sign up
              </Link>
            </div>
          </div>
          <form className="space-y-2" onSubmit={signinHandler}>
            <div className="mt-2">
              <label htmlFor="email" className=" text-lg font-semibold">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className=" relative block w-full px-3 py-2 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Enter email id"
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="text-lg font-semibold">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="off"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="relative block w-full px-3 py-2 border rounded-md border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder=" Enter password"
                required
              />
            </div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black">
              Sign in
            </button>
          </form>
          <div className="text-base text-center my-4">
            <Link
              exact="true"
              to="/auth/user_reset_password"
              className="font-semibold text-red-500 hover:text-indigo-500">
              Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Signin;
