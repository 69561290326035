import React from "react";
import { Link } from "react-router-dom";

const ChapterList = ({ name, id }) => {
  return (
    <>
      <div className="flex items-center justify-between  mx-2 px-2 py-2 hover:bg-gray-50 rounded-md">
        <Link
          exact="true"
          to={`/courses/exams/examcategory/subjects/${id}`}
          className="font-medium flex  hover:font-semibold  capitalize text- hover:text-indigo-600 text-gray-900"
        >
          {name}
        </Link>
      </div>
    </>
  );
};

export default ChapterList;
