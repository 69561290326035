import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import AuthContext from "../../../store/authContext";
import MicrostudyApi from "../../../api/MicrostudyApi";
import GetChapterpdf from "./GetChapterpdf";
import { toast } from "react-toastify";

const GetPdfUrl = () => {
  const { id } = useParams();
  const [pdfData, setPdfData] = useState(
    "uploaded-pdf/2021-10-07T16-37-46.184Z-Scaned-F-1-English.pdf"
  );
  const [pdfUrl, setPdfUrl] = useState("");
  const authContext = useContext(AuthContext);
  useEffect(() => {
    const getPdfLink = async () => {
      try {
        const token = authContext.token;
        const response = await MicrostudyApi.get(
          `/user/books-pdf-fetch/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status !== 201 || !response) {
          toast("Server didn't respond.");
        } else {
          const bookData = response.data["books"];
          setPdfData(bookData["pdfUrl"]);
          setPdfUrl(`https://api.microstudy.in/` + pdfData);
        }
      } catch (error) {
        toast(error.message);
      }
    };
    getPdfLink();
  });

  return (
    <>
      <GetChapterpdf url={pdfUrl} />
    </>
  );
};

export default GetPdfUrl;
