import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AuthContext from "../store/authContext";
import TestSeries from "../layouts/testSeries/TestSeries";
// components
import Sidebar from "../components/Sidebar/Sidebar";
import FooterAdmin from "../components/Footers/FooterAdmin";
// pricing
import Pricing from "./Courses/Pricing";
//courses
import Dashboard from "./Courses/Dashboard";

import GetChapterpdf from "./Courses/Deled/GetPdfUrl";

const Courses = () => {
  const authContext = useContext(AuthContext);
  const isSignedin = authContext.isAuthenticated;

  return (
    <>
      <Sidebar />
      <div className="md:ml-64 bg-white">
        <div className="px-4 md:px-10 mx-auto w-full">
          <Switch>
            {/*exams start */}
            <Route
              path="/courses/exams/examcategory/subjects/:id"
              component={GetChapterpdf}
            />
            <Route path="/courses/test-series/:id" component={TestSeries} />
            {/*exams end */}

            <Route path="/courses/dashboard" exact component={Dashboard} />
            <Route exact path="/courses/pricing">
              {!isSignedin ? <Redirect to="/auth/Signin" /> : <Pricing />}
            </Route>
            <Route path="/courses/pricing" exact component={Pricing} />
            <Redirect from="/Courses" to="/Courses/dashboard" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
};
export default Courses;
