import React from "react";

const Terms = () => {
  return (
    <>
      <div className="m-5 lg:m-10">
        <div className="w-full mx-auto  py-10 lg:py-20">
          <div className="flex">
            <h2 className="text-4xl sm:text-5xl font-black tracking-wide text-center text-gray-900 mb-10">
              Terms And Conditions
            </h2>
          </div>
          <div className="text-lg  text-gray-800">
            <p className="mt-2  leading-loose">Last updated: 06 Dec 2020</p>

            <p className="mt-2  leading-loose">
              Please read these terms and conditions carefully before using Our
              Service.
            </p>

            <h1 className="text-3xl font-bold mt-10">Generel</h1>

            <p className="mt-2  leading-loose">
              The Micro Study website ("Website") and related services (together
              with the Website, the "Service") are operated by Micro
              Publications. Access and use of the Service is subject to the
              following Terms and Conditions of Service ("Terms and
              Conditions"). By accessing or using any part of the Service, you
              represent that you have read, understood, and agree to be bound by
              these Terms and Conditions including any future modifications.
              Micro Study may amend, update or change these Terms and
              Conditions. If we do this, we will post a notice that we have made
              changes to these Terms and Conditions on the Website for at least
              7 days after the changes are posted and will indicate at the
              bottom of the Terms and Conditions the date these terms were last
              revised. Any revisions to these Terms and Conditions will become
              effective the earlier of (i) the end of such 7-day period or (ii)
              the first time you access or use the Service after such changes.
              If you do not agree to abide by these Terms and Conditions, you
              are not authorized to use, access or participate in the Service.
            </p>

            <h2 className="text-2xl font-bold mt-8">
              Description of Website and Service
            </h2>
            <p className="mt-2  leading-loose">
              The Service allows users to (i) prepare for any online
              examination. Users interested in learning or practicing are
              presented with different types of educational activities. Micro
              Study may, in its sole discretion and at any time, update, change,
              suspend, make improvements to or discontinue any aspect of the
              Service, temporarily or permanently.
            </p>

            <h1 className="text-3xl font-bold mt-10">Registration</h1>
            <p className="mt-2  leading-loose">
              In connection with registering for and using the Service, you
              agree (i) to provide accurate, current and complete information
              about you and/or your organization as requested by Micro Study;
              (ii) to maintain the confidentiality of your password and other
              information related to the security of your account; (iii) to
              maintain and promptly update any registration information you
              provide to Micro Study while registering or during your usage of
              Micro Study services, to keep such information accurate, current
              and complete; (iv) to be fully responsible for all use of your
              account and for any actions that take place through your account;
              (v) By signing up on Micro Study you deemed to have given your
              consent to be contacted by us via phone calls and/or SMS
              notifications and offer you our services, imparting product
              knowledge, offer promotional, offers running on website/app,
              offers offered by the associated third parties and also
              order/shipment/delivery related updates. (vi) You are required to
              provide your examination registration information for the purpose
              of our internal estimation of successful candidates. If you do not
              provide the information, your partial or full access to the
              website content and/or other Micro Study services may be blocked.
              No refund will be provided in such scenario. We assure you that we
              will not use this information for any other purpose or share this
              information with any third party.
            </p>

            <h1 className="text-3xl font-bold mt-10">
              Your Representations and Warranties.
            </h1>
            <p className="mt-2  leading-loose">
              You represent and warrant to Micro Study that your access and use
              of the Service will be in accordance with these Terms and
              Conditions and with all applicable laws, rules and regulations of
              Constitution of India and any other relevant jurisdiction,
              including those regarding online conduct or acceptable content,
              and those regarding the transmission of data or information
              exported from India and/or the jurisdiction in which you reside.
              You further represent and warrant that you have created or own any
              material you submit to Micro Study and that you have the right to
              grant us a license to use that material.
            </p>
            <h1 className="text-3xl font-bold mt-10">Inappropriate Use</h1>
            <p className="mt-2  leading-loose">
              You will not upload, display or otherwise provide on or through
              the Service any content that: (i) is libelous, defamatory,
              abusive, threatening, harassing, hateful, offensive or otherwise
              violates any law or infringes upon the right of any third party
              (including copyright, trademark, privacy, publicity or other
              personal or proprietary rights); or (ii) in Micro Study’s sole
              judgment, is objectionable or which restricts or inhibits any
              other person from using the Service or which may expose Micro
              Study or its users to any harm or liability of any kind.
            </p>

            <h1 className="text-3xl font-bold mt-10">
              Indemnification of Microstudy
            </h1>
            <p className="mt-2  leading-loose">
              You agree to defend, indemnify and hold harmless Micro Study and
              its directors, officers, employees, contractors, agents,
              suppliers, licensors, successors and assigns, from and against any
              and all losses, claims, causes of action, obligations, liabilities
              and damages whatsoever, including attorneys' fees, arising out of
              or relating to your access or use of the Service, any false
              representation made to us (as part of these Terms and Conditions
              or otherwise), your breach of any of these Terms and Conditions,
              or any claim that any service we provide to you is inaccurate,
              inappropriate or defective in any way whatsoever.
            </p>

            <h1 className="text-3xl font-bold mt-10">
              No Representations or Warranties
            </h1>
            <p className="mt-2  leading-loose">
              The service, including all images, audio files and other content
              therein, and any other information, property and rights granted or
              provided to you by Micro Study are provided to you on an "as is"
              basis. Micro Study and its suppliers make no representations or
              warranties of any kind with respect to the service, either express
              or implied, and all such representations and warranties, including
              warranties of merchantability, fitness for a particular purpose or
              non-infringement, are expressly disclaimed. Without limiting the
              generality of the foregoing, Micro Study does not make any
              representation or warranty of any kind relating to accuracy,
              service availability, completeness, informational content,
              error-free operation, results to be obtained from use, or
              non-infringement. Access and use of the service may be unavailable
              during periods of peak demand, system upgrades, malfunctions or
              scheduled or unscheduled maintenance or for other reasons. Some
              jurisdictions do not allow the exclusion of implied warranties, so
              the above exclusion may not apply to you.
            </p>

            <h1 className="text-3xl font-bold mt-10">
              Limitation on types of Damages/Limitation of Liability
            </h1>
            <p className="mt-2  leading-loose">
              In no event will Micro Study be liable to you or any third party
              claiming through you (whether based in contract, tort, strict
              liability or other theory) for indirect, incidental, special,
              consequential or exemplary damages arising out of or relating to
              the access or use of, or the inability to access or use, the
              service or any portion thereof, including but not limited to the
              loss of use of the service, inaccurate results, loss of profits,
              business interruption, or damages stemming from loss or corruption
              of data or data being rendered inaccurate, the cost of recovering
              any data, the cost of substitute services or claims by third
              parties for any damage to computers, software, modems, telephones
              or other property, even if Micro Study has been advised of the
              possibility of such damages. Micro Study’s liability to you or any
              third party claiming through you for any cause whatsoever, and
              regardless of the form of the action, is limited to the amount
              paid, if any, by you to Micro Study for the service in the 12
              months prior to the initial action giving rise to liability. This
              is an aggregate limit. The existence of more than one claim
              hereunder will not increase this limit.
            </p>

            <h1 className="text-3xl font-bold mt-10">Termination</h1>
            <p className="mt-2  leading-loose">
              Micro Study may terminate your access and use of the Service
              immediately at any time, for any reason, and at such time you will
              have no further right to use the Service. You may terminate your
              Micro Study account at any time by following the instructions
              available through the Service. The provisions of these Terms and
              Conditions relating to the protection and enforcement of Micro
              Study’s proprietary rights, your representations and warranties,
              disclaimer of representations and warranties, release and
              indemnities, limitations of liability and types of damages,
              ownership of data and information, governing law and venue, and
              miscellaneous provisions shall survive any such termination.
            </p>

            <h1 className="text-3xl font-bold mt-10">
              Proprietary Rights in Service Content and Activity Data
            </h1>
            <p className="mt-2  leading-loose">
              All content available through the Service, including designs,
              text, graphics, images, information, software, audio and other
              files, and their selection and arrangement (the "Service
              Content"), are the proprietary property of Micro Study or its
              licensors. No Service Content may be modified, copied,
              distributed, framed, reproduced, republished, downloaded, scraped,
              displayed, posted, transmitted, or sold in any form or by any
              means, in whole or in part, other than as expressly permitted in
              these Terms and Conditions. You may not use any data mining,
              robots, scraping or similar data gathering or extraction methods
              to obtain Service Content. As between you and Micro Study, all
              data and information generated from your access and use of the
              educational activities made available on or through the Service,
              including translated content generated by you (collectively, the
              "Activity Data"), shall be exclusively owned by Micro Study, and
              you shall not have any right to use such Activity Data except as
              expressly authorized by these Terms and Conditions. Activity Data
              will not include material you submit for translation or any
              resulting translation. By using the Service, you hereby assign to
              Micro Study any and all rights, title and interest, including any
              intellectual property rights or proprietary rights, in the
              Activity Data. All rights of Micro Study or its licensors that are
              not expressly granted in these Terms and Conditions are reserved
              to Micro Study and its licensors.
            </p>

            <h1 className="text-3xl font-bold mt-10">Trademarks</h1>
            <p className="mt-2  leading-loose">
              "Micro Study" and all other trademarks, service marks, graphics
              and logos used in connection with the Service are trademarks or
              service marks of Micro Study or their respective owners. Access
              and use of the Service does not grant or provide you with the
              right or license to reproduce or otherwise use the Micro Study
              name or any Micro Study or third-party trademarks, service marks,
              graphics or logos.
            </p>

            <h2 className="text-2xl font-bold mt-8">Privacy</h2>
            <p className="mt-2  leading-loose">
              Use of the Service is also governed by our Privacy Policy, a copy
              of which is located at http://www.microstudy.org. By using the
              Service, you consent to the terms of the Privacy Policy.
            </p>

            <h2 className="text-2xl font-bold mt-8">Cancellation Policy</h2>
            <p className="mt-2  leading-loose">
              Cancellations will be considered only if the cancellation request
              is made within 24 hours of placing the order. However, the
              cancellation request will not be entertained if the orders have
              already been completed or if we have initiated the process of
              shipping either by ourselves or through the
              vendors/merchants/representatives whom we may appoint from time to
              time. No cancellations are allowed for products on which Micro
              Study marketing team has given special offers for various purposes
              including but not limited to special occasions like New Year,
              festivals, specific examination etc. These are limited validity
              offers and therefore cancellations are not possible. In case you
              feel that the product received is not as shown on the site and/or
              as per your expectations, you must bring it to the notice of our
              customer service within 24 hours of receiving the product. The
              customer service team, after looking into your complaint, will
              make an appropriate decision.
            </p>

            <h1 className="text-3xl font-bold mt-10">Refund Policy</h1>
            <p className="mt-2  leading-loose">
              The refund is applicable for our products/services only until it’s
              dispatched or the login details for online access to test material
              etc. are disclosed/dispatched to you. Please include your order
              number (sent to you via email after your order) and do tell us why
              you’re requesting a refund. We take customer feedback very
              seriously and use it to constantly improve our products and
              quality of service. All refunds, if eligible, will be processed
              within 15 (Fifteen) business days of receiving cancellation
              request and mode of refund would be same through which the
              original transaction was done.
            </p>

            <h1 className="text-3xl font-bold mt-10">
              Notice for Claims of Copyright Violations and Agent for Notice
            </h1>
            <p className="mt-2  leading-loose">
              If you are a copyright owner and have a good faith belief that any
              material available through the service infringes upon your
              copyrights, you may submit a copyright infringement notification
              to Micro Study pursuant to the Digital Millennium Copyright Act by
              providing us with the following information in writing: an
              electronic or physical signature of the copyright owner or the
              person authorized to act on behalf of the owner of the copyright
              interest; a description of the copyrighted work that you claim has
              been infringed; a description of where the material that you claim
              is infringing is located on the Service, with enough detail that
              we may find it on the Service; your address, telephone number, and
              email address; a statement by you that you have a good faith
              belief that the disputed use is not authorized by the copyright
              owner, its agent, or the law; and a statement by you, made under
              penalty of perjury, that the above information in your notice is
              accurate and that you are the copyright owner or are authorized to
              act on the copyright owner's behalf. Please consult your legal
              counsel for further details or see Section 51 of The Copyright
              Act, 1957. Micro Study’s Agent for Notice of claims of copyright
              infringement can be reached as follows: By email:
              support@microstudy.org
            </p>
            <p className="mt-2  leading-loose">
              Governing Law and Arbitration These Terms and Conditions, its
              subject matter and Micro Study’s and your respective rights under
              these Terms and Conditions shall be governed by the laws of India
              and you agree that the courts of Bhagalpur will have exclusive
              jurisdiction over any dispute (contractual or non-contractual)
              concerning these terms.
            </p>
            <h1 className="text-3xl font-bold mt-10">Miscellaneous</h1>
            <p className="mt-2  leading-loose">
              These Terms and Conditions constitute the entire agreement between
              Micro Study and you concerning the subject matter hereof. In the
              event that any of the Terms and Conditions are held by a court or
              other tribunal of competent jurisdiction to be unenforceable, such
              provisions shall be limited or eliminated to the minimum extent
              necessary so that these Terms and Conditions shall otherwise
              remain in full force and effect. A waiver by Micro Study or you of
              any provision of these Terms and Conditions or any breach thereof,
              in any one instance, will not waive such term or condition or any
              subsequent breach thereof. Micro Study may assign its rights or
              obligations under these Terms and Conditions without condition.
              These Terms and Conditions will be binding upon and will inure to
              the benefit of Micro Study and you, and Micro Study’s and your
              respective successors and permitted assigns.
            </p>
            <h1 className="text-3xl font-bold mt-10">Contact Us</h1>
            <p classname="mt-2  leading-loose">
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <div className="  text-gray-700 mt-8">
              <span className="text-center items-center">
                <a
                  target="_blank"
                  href="tel:+917992257628"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg items-center hover:underline font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  +91 799 225 7628
                </a>
                <a
                  target="_blank"
                  href="tel:+918709362272"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg items-center hover:underline font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  +91 870 936 2272
                </a>
              </span>
              <span className=" ">
                <a
                  target="_blank"
                  href="mailto:support@microstudy.org"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg hover:underline  items-center font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  support@microstudy.org
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Terms;
