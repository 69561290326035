import React from "react";
import { Link } from "react-router-dom";
import logo from "../../img/apple-touch-icon.png";
const cyear = new Date().getFullYear();
export default function Footer() {
  return (
    <>
      <footer className="bg-gray-200 pt-8 pb-6">
        <div
          className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-gray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold">
                <Link
                  exact="true"
                  to="/"
                  className="flex items-center text-3xl px-3 py-2 font-bold rounded-md"
                >
                  <img alt="" className="h-8 w-auto" src={logo} />
                  <span className="text-black px-2">Microstudy</span>
                </Link>
              </h4>
              <p className="max-w-md mt-2 px-4 text-lg text-left text-gray-700">
                a one stop destination for all government, academic and
                competitive exams preparation courses, study materias and Test
                Series.
              </p>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top ">
                <div className="w-full lg:w-4/12 px-4 text-left">
                  <span className="block uppercase text-gray-900 text-lg font-semibold mb-2">
                    <Link to="/Courses/dashboard">Our Courses</Link>
                  </span>
                  <ul className="list-unstyled">
                    <Link
                      exact="true"
                      to="/Courses/dashboard/deled"
                      className="block mt-2 text-base font-semibold text-gray-600 dark:text-gray-400 hover:underline"
                    >
                      D.EL.ED
                    </Link>
                    <li>
                      <Link
                        exact="true"
                        to="/Courses/dashboard/bed"
                        className="block mt-2 text-base font-semibold text-gray-600 dark:text-gray-400 hover:underline"
                      >
                        B.Ed
                      </Link>
                    </li>
                    <li>
                      <Link
                        exact="true"
                        to="/Courses/dashboard/bpsc"
                        className="block mt-2 text-base font-semibold text-gray-600 dark:text-gray-400 hover:underline"
                      >
                        BPSC
                      </Link>
                    </li>
                    <li>
                      <Link
                        exact="true"
                        to="/Courses/dashboard/jpsc"
                        className="block mt-2 text-base  font-semibold text-gray-600 dark:text-gray-400 hover:underline"
                      >
                        JPSC
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4 text-left">
                  <span className="block uppercase text-gray-900 text-lg font-semibold mb-2">
                    Other Resources
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <Link
                        exact
                        className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-lg"
                        to="/terms_and_conditions"
                      >
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        exact
                        className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-lg"
                        to="/privacy_and_policy"
                      >
                        Privacy & Policy
                      </Link>
                    </li>
                    <li>
                      <a
                        className="text-gray-600 hover:text-gray-800 font-semibold block pb-2 text-lg"
                        href="#Contact"
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-300" />
          <div className="flex flex-col items-center  space-x-7 justify-between mt-6 md:flex-row">
            <div className="text-left">
              <p className="text-lg font-semibold  text-indigo-700">
                Copyright © {cyear} Microstudy.
              </p>
            </div>
            <div className="flex text-left">
              <div className=" ">
                Design & Developed by
                <Link
                  className="mr-8 ml-2 font-semibold text-xl text-indigo-700 hover:underline"
                  target="_blank"
                  to={{
                    pathname: "https://codeinverse.com",
                  }}
                >
                  Codeinverse
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
