import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MicrostudyApi from "../../api/MicrostudyApi";
import GetExam from "../Courses/GetExam";

const Pricing = () => {
  const [priceData, setPriceData] = useState([]);
  useEffect(() => {
    getExamList();
  }, []);
  const getExamList = async () => {
    try {
      const response = await MicrostudyApi.get(`user/courses-fetch`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        setPriceData(response.data["courses"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.message.message);
    }
  };

  return (
    <>
      <div className="my-5 ">
        {priceData.length > 0 ? (
          priceData.map((val) => {
            return <GetExam key={val.id} name={val.name} id={val.id} />;
          })
        ) : (
          <h1 className="text-2xl font-bold text-center mx-auto my-auto">
            Comming Soon
          </h1>
        )}{" "}
      </div>
    </>
  );
};

export default Pricing;
