/*eslint-disable*/
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/favicon32.png";
import ExamList from "./ExamList";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
const Sidebar = () => {
  const [collapseShow, setCollapseShow] = useState("hidden");

  return (
    <>
      <div className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-lg bg-indigo-400 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-4">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap  flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler desktop */}
          <div className=" md:hidden">
            <button
              type="button"
              onClick={() => setCollapseShow("bg-indigo-200 m-2 py-3 px-6")}
              className="rounded-md p-1 inline-flex items-center justify-center text-black "
            >
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-7 w-7" aria-hidden="true" />
            </button>
          </div>
          <Link
            exact="true"
            to="/"
            className="flex-shrink-0 flex items-center justify-center text-3xl px-3 py-2 font-bold rounded-md "
          >
            <img alt="" className="h-7 w-7" src={logo} />
            <span className="text-black  px-2">Microstudy</span>
          </Link>
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className="md:min-w-full md:hidden block">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    exact="true"
                    to="/"
                    className="flex-shrink-0 flex items-center text-3xl  py-2 font-bold   "
                  >
                    <img alt="logo" className="w-7 h-7" src={logo} />
                    <span className="text-black  mx-2">Microstudy</span>
                  </Link>
                </div>
                <div className="w-6/12 flex right-0 justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-red-500 opacity-100 md:hidden p-1 text-xl leading-none "
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <XIcon className="h-7 w-7" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <ul className="md:flex-col md:min-w-full flex my-4 space-y-3 flex-col">
              <li className="items-center">
                <Link
                  onClick={() => setCollapseShow("hidden")}
                  className=" text-lg font-bold block cursor-pointer text-black"
                  to="/Courses/dahboard"
                >
                  Dashboard
                </Link>
              </li>
              <li className="items-center">
                <Link
                  onClick={() => setCollapseShow("hidden")}
                  className=" text-lg font-bold block cursor-pointer text-black"
                  to="/Courses/pricing"
                >
                  Courses
                </Link>
              </li>
              <Disclosure as="div">
                {({ open }) => (
                  <>
                    <div className=" flow-root">
                      <Disclosure.Button className="w-full flex items-center justify-between text-base text-gray-900 ">
                        <span className="font-bold  capitalize text-lg  text-black">
                          My learning
                        </span>
                        <span className="flex items-center">
                          {open ? (
                            <ChevronUpIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDownIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </div>
                    <Disclosure.Panel>
                      <div className="my-3 space-y-3">
                        <ExamList />
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
