import React from "react";
import data from "../../components/data";
import HeaderStats from "../../components/Headers/HeaderStats";
import PriceCard from "../../components/Cards/PriceCard";
import Notification from "../../components/Notification";
const Dashboard = () => {
  return (
    <>
      <div className="my-5">
        <Notification />
        <HeaderStats />
        <div className="mx-auto container px-4  xl:px-0 ">
          <div className="flex flex-col w-full items-center justify-center f-f-l">
            {data?.map((val) => {
              return (
                <PriceCard
                  key={val.heading}
                  heading={val.heading}
                  disc={val.disc}
                  list={val.list}
                  link={val.link}
                  image={val.image}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
