import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import { Link } from "react-router-dom";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import SubjectList from "./SubjectList";
import QuizList from "./QuizList";
import AuthContext from "../../store/authContext";
import { Disclosure } from "@headlessui/react";
import { toast } from "react-toastify";
const ExamCategery = ({ name, id }) => {
  const authContext = useContext(AuthContext);
  const [examCategoryData, setExamCategoryData] = useState([]);
  useEffect(() => {
    getExamCategoryList();
  }, []);
  const getExamCategoryList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(
        `/user/exams-category-fetch/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setExamCategoryData(response.data["purchasedExamcategory"]);
      if (response.status !== 200 || !response) {
        toast(response.statusText);
      } else {
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <>
      {name === "Prelims Test Series" || name === "Mains Test Series" ? (
        <div className="my-3">
          <QuizList key={id} name={name} id={id} />
        </div>
      ) : (
        <Disclosure as="div" key={id}>
          {({ open }) => (
            <>
              <div className=" flow-root">
                <Disclosure.Button className="w-full flex items-center justify-between ">
                  <span className="font-medium capitalize text-lg  text-black">
                    {name}
                  </span>
                  <span className="flex items-center">
                    {open ? (
                      <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </div>
              <Disclosure.Panel>
                <div className="space-y-3 my-3">
                  {examCategoryData?.map((purchasedExamcategory) => (
                    <SubjectList
                      key={purchasedExamcategory["id"]}
                      name={purchasedExamcategory["name"]}
                      id={purchasedExamcategory["id"]}
                    />
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      )}
    </>
  );
};

export default ExamCategery;
