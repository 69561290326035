import React, { useState, useEffect, useRef } from "react";

const Question = ({
  data,
  onAnswerUpdate,
  numberOfQuestions,
  activeQuestion,
  onSetActiveQuestion,
  onSetStep,
}) => {
  const [selected, setSelected] = useState("");
  const [error, setError] = useState("");
  const radiosWrapper = useRef();

  useEffect(() => {
    const findCheckedInput =
      radiosWrapper.current.querySelector("input:checked");
    if (findCheckedInput) {
      findCheckedInput.checked = false;
    }
  }, [data]);

  const changeHandler = (e) => {
    setSelected(e.target.value);
    if (error) {
      setError("");
    }
  };

  const nextClickHandler = (e) => {
    if (selected === "") {
      return setError("Please select one option!");
    }
    onAnswerUpdate((prevState) => [
      ...prevState,
      { q: data.questiontext, a: selected },
    ]);
    setSelected("");
    if (activeQuestion < numberOfQuestions - 1) {
      onSetActiveQuestion(activeQuestion + 1);
    } else {
      onSetStep(3);
    }
  };

  return (
    <>
      <div className="mt-5 h-screen  md:col-span-2">
        <div className="shadow-md overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-gray-50 space-y-6 sm:p-6">
            <h1 className="text-lg font-semibold capitalize text-gray-900">
              Q {data.questiontext}
            </h1>
            <h1 className="text-lg font-semibold capitalize text-gray-900">
              {data.questiontexthindi}
            </h1>
            {data.questionImgURL === "" ? (
              <div />
            ) : (
              <img
                alt="question img"
                className="w-full  object-contain md:object-scale-down"
                src={`https://api.microstudy.org/${data.questionImgURL}`}
              ></img>
            )}
            <div className="mt-4 space-y-4">
              <div className="flex items-start">
                <div className="control" ref={radiosWrapper}>
                  {data.options?.map(({ optiontext }, i) => (
                    <label
                      className="flex justify-start items-center text-base font-medium   text-gray-900"
                      key={i}
                    >
                      <input
                        type="radio"
                        name="answer"
                        value={optiontext}
                        className="focus:ring-indigo-500 h-4 w-4 mx-4 text-indigo-600 border-gray-800"
                        onChange={changeHandler}
                      />
                      {optiontext}
                    </label>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 flex justify-between sm:px-6">
            <span className="text-left">
              {" "}
              {error && <div className="text-red-500">{error}</div>}
            </span>
            <button
              className="flex justify-end items-end text-right py-2 px-6 border border-transparent shadow-sm text-lg font-medium rounded-md text-gray-900 bg-indigo-400  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
              onClick={() => {
                nextClickHandler();
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
