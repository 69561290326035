import React, { useEffect } from "react";
import ViewSDKClient from "../../../components/ViewSDKClient";

const RenderMenu = ({ url }) => {
  useEffect(() => {
    const loadPDF = async () => {
      const viewSDKClient = new ViewSDKClient();
      await viewSDKClient.ready();

      try {
        // Attempt to preview the PDF in the "adobe-dc-view" element.
        await viewSDKClient.previewFile(
          "adobe-dc-view", // Ensure this matches the ID in your HTML
          {
            defaultViewMode: "FIT_WIDTH",
            showAnnotationTools: false,
            showLeftHandPanel: false,
            showPageControls: false,
            showDownloadPDF: false,
            showPrintPDF: false,
          },
          url
        );
      } catch (error) {
        console.error(error.message); // Log any errors
      }
    };

    loadPDF();
  }, [url]);

  return (
    <div className="my-3">
      {/* Make sure you have an HTML element with the ID "adobe-dc-view" */}
      <div id="adobe-dc-view" className="full-window-div border border-gray-100 h-screen"></div>
    </div>
  );
};

export default RenderMenu;
