import React, { useContext } from "react";
import UserContext from "../../store/userContext";
export default function HeaderStats() {
  const userContext = useContext(UserContext);
  const Name = userContext.userName;
  let greetings = "";
  let CurrStyle = "";
  const cTime = new Date().getHours();
  if (cTime >= 1 && cTime < 12) {
    greetings = "Good Morning";
    CurrStyle = "text-lg lg:text-5xl py-2 font-bold text-green-800 text-center";
  } else if (cTime >= 12 && cTime < 18) {
    greetings = "Good Afternoon";
    CurrStyle =
      "text-lg lg:text-5xl py-2 font-bold text-yellow-800 text-center";
  } else {
    greetings = "Good Evening";
    CurrStyle = "text-lg lg:text-5xl py-2 font-bold text-gray-800 text-center";
  }
  return (
    <>
      <div className="mx-auto container mt-5 space-y-5">
        <div>
          <h1 className={CurrStyle}>
            {greetings} {Name ? Name : `Guest`}
          </h1>
          <h1 className="text-2xl  lg:text-6xl py-4  text-gray-900 font-black text-center">
            Welcome to Microstudy
          </h1>
        </div>
      </div>
    </>
  );
}
