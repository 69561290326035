import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import Courses from "./layouts/Courses";
import Auth from "./layouts/Auth";
import Index from "./Pages/Home";
import Contact from "./Pages/Contact";
import AuthContext from "./store/authContext";
import Terms from "./Pages/Terms";
import Policy from "./Pages/Policy";
//Tostify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//google Analitics
import ReactGA from "react-ga";
ReactGA.initialize("UA-212201167-1");

const App = () => {
  const authContext = useContext(AuthContext);
  const isSignedin = authContext.isAuthenticated;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <Switch>
        <Route path="/courses">
          <Courses />
        </Route>
        {!isSignedin && (
          <Route path="/auth">
            <Auth />
          </Route>
        )}
        <Route path="/terms_and_conditions">
          <Terms />
        </Route>
        <Route path="/privacy_and_policy">
          <Policy />
        </Route>

        <Route path="/Contact" exact>
          <Contact />
        </Route>
        <Route path="/" exact>
          <Index />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default withRouter(App);
