import React from "react";
import { Link } from "react-router-dom";
const GetQuiz = ({ testname, id }) => {
  return (
    <div className="flex items-center justify-between  mx-2 px-2 py-2 hover:bg-gray-50 rounded-md">
      <Link
        exact
        to={`/courses/test-series/${id}`}
        className="font-base flex  hover:font-semibold  capitalize text-lg hover:text-indigo-600 text-gray-900"
      >
        {testname}
      </Link>
    </div>
  );
};

export default GetQuiz;
