import React, { useContext } from "react";
import { LockClosedIcon } from "@heroicons/react/outline";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import UserContext from "../../store/userContext";
import { toast } from "react-toastify";
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const ExamPricingCard = ({ name, id, price }) => {
  const authContext = useContext(AuthContext);
  const userContext = useContext(UserContext);
  let examData = [];
  let orderData = [];

  async function displayRazorpay() {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(`/user/exams-fetch/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      examData = response.data["examData"];

      orderData = response.data["orderData"];
    } catch (error) {
      toast(error.response.data.message);
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!res) {
      toast("Payment failed to load !");
      return;
    }

    const options = {
      key: "rzp_live_WsuDTXHgzYGcC8",
      currency: "INR",
      amount: orderData["amount"],
      order_id: orderData["id"],
      name: "MicroStudy",
      description: examData["name"],
      image:
        "https://microstudy.org/static/media/apple-touch-icon.c8a8a81c.png",
      handler: function (response) {
        try {
          const token = authContext.token;
          MicrostudyApi.post(
            `/user/create-invoice`,
            {
              paymentId: response.razorpay_payment_id,
              examId: examData["id"],
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.status === 201) {
            toast(response.data.message);
          } else {
            toast("Payment Failed!");
          }
        } catch (error) {
          toast(error.response.data.message);
        }
      },
      prefill: {
        name: userContext.userName,
        email: userContext.userEmail,
        phone_number: userContext.userPhone,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      toast(response.error.code);
      toast(response.error.description);
      toast(response.error.source);
      toast(response.error.step);
      toast(response.error.reason);
      toast(response.error.metadata.order_id);
      toast(response.error.metadata.payment_id);
    });
  }

  return (
    <>
      <div className="flex flex-wrap sm:flex-no-wrap items-center justify-between w-full">
        <div className="w-full mx-2 p-5 h-auto rounded-md   bg-indigo-400  hover:shadow-xl  focus:ring focus:ring-offset-1 focus:ring-gray-800 ">
          <div className="space-y-5">
            <h1 className="flex  justify-start items-start font-semibold capitalize  text-gray-900 text-lg">
              {name}
            </h1>
            <ul className="flex flex-col my-6">
              <li className="flex items-center mb-2.5">
                <img
                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                  className="mr-4"
                  alt="check-mark"
                />
                <p className="text-gray-800 text-base font-medium">
                  Full lifetime access
                </p>
              </li>
              <li className="flex items-center mb-2.5">
                <img
                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                  className="mr-4"
                  alt="check-mark"
                />
                <p className="text-gray-800 text-base font-medium">
                  24/7 Support
                </p>
              </li>
              <li className="flex items-center mb-2.5">
                <img
                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                  className="mr-4"
                  alt="check-mark"
                />
                <p className="text-gray-800 text-base font-medium">
                  Access on android
                </p>
              </li>
            </ul>
            <div className="flex  justify-start items-start">
              <h1 className=" text-center px-3 text-2xl text-gray-900 font-bold">
                <span className="font-black text-2xl px-1">₹</span>
                {price}
              </h1>
            </div>
          </div>

          <div className="flex mt-4">
            <button
              onClick={displayRazorpay}
              className=" w-full text-indigo-700 focus:outline-none transition duration-150 ease-in-out rounded-md bg-gray-100  shadow px-8 py-3 text-lg font-bold  focus:ring-2 focus:ring-offset-1 focus:ring-indigo-600"
            >
              Enroll Now
            </button>
          </div>
          <div className="flex justify-center items-center text-sm  my-2 font-light">
            <LockClosedIcon className="w-5 h-5 mr-1" />
            Secure Transaction
          </div>
        </div>
      </div>
    </>
  );
};
export default ExamPricingCard;
