import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useRef } from "react";
import logo from "../../img/apple-touch-icon.png";
import MicrostudyApi from "../../api/MicrostudyApi";
import UserContext from "../../store/userContext";
import { toast } from "react-toastify";
const Signup = () => {
  const userContext = useContext(UserContext);
  const emailInputRef = useRef();
  const history = useHistory();

  const signUpHandler = async (e) => {
    e.preventDefault();

    const email = emailInputRef.current.value;

    try {
      const response = await MicrostudyApi.post(
        "/auth/user/user-password-reset",
        {
          email,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 201) {
        userContext.setUserEmail(email);
        history.replace("/auth/user_update_password");
      } else {
        toast("invalid credentilas!");
      }
    } catch (error) {
      toast(error.response.message);
    }
  };
  return (
    <>
      <div className="min-h-screen px-6  flex items-center justify-center ">
        <div className="max-w-md w-full">
          <div className="">
            <img alt="" className="mx-auto h-16 w-auto" src={logo} />
            <h2 className="mt-6  text-center text-3xl font-bold text-gray-900">
              Reset Password
            </h2>
          </div>
          <form className="  my-5" onSubmit={signUpHandler}>
            <div className="rounded-md shadow-sm ">
              <div className="mt-2 space-y-1">
                <label htmlFor="email" className=" my-3 text-lg font-semibold">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  ref={emailInputRef}
                  required
                  className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email Address"
                />
              </div>
            </div>

            <button
              type="submit"
              className="group relative w-full flex justify-center  my-5 py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
            >
              Send Code
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Signup;
