import React from "react";
import { Link } from "react-router-dom";
import first from "../img/FIRST.png";
import second from "../img/second.png";
import Rtl from "../img/RTL.png";
import Bpsc from "../img/BPSC Cover.png";
const data = [
  {
    id: 1,
    heading: "MICRO BPSC PRACTICE SET (PRELIMS)",
    disc: "Md Obaid, M.J.Akhtar, M.S.Ali, Rohit Kumar, Vidyasagar, Er.Aamir Raza, Rajan Gupta",
    imgsrc: Bpsc,
    flink: "https://dl.flipkart.com/s/die7J9uuuN",
    alink:
      "https://www.amazon.in/dp/8195203426/ref=cm_sw_r_apan_glt_fabc_JMXS4CW97BC7GMNQSMC3",
  },
  {
    id: 2,
    heading: "D.El.ED (Regular) Guide 1st Year",
    disc: "Md Obaid, Md Shahzad Ali, Vidyasagar",
    imgsrc: first,
    flink: "https://dl.flipkart.com/s/JJ!pmmuuuN",
    alink:
      "https://www.amazon.in/dp/B08YKBS5KX/ref=cm_sw_r_em_apa_glt_i_VF9003NNAEN0E4D53B8D",
  },
  {
    id: 3,
    heading: "D.El.ED (Regular) Guide 2nd Year",
    disc: "Md Obaid, Md Shahzad Ali, Vidyasagar and Rohit kumar",
    imgsrc: second,
    flink: "https://dl.flipkart.com/s/YYzLgSNNNN",
    alink:
      "https://www.amazon.in/dp/B08ZNG534N/ref=cm_sw_r_em_apa_glt_i_3T4280GCAJ6TT18KKK1W?_encoding=UTF8&psc=1",
  },
  {
    id: 4,
    heading: "B.Ed (MANUU) 1st Sem ",
    disc: "Md Obaid and Md Shahzad Ali",
    imgsrc: Rtl,
    flink: "https://dl.flipkart.com/s/JJ_2MmuuuN",
    alink:
      "https://www.amazon.in/dp/B098QPC1SX/ref=cm_sw_r_em_apa_glt_i_AWJKYPSRE7G9N189QJ16",
  },
];

const Card = ({ id, heading, disc, imgsrc, flink, alink }) => {
  return (
    <>
      <div className="container" key={id}>
        <div className="w-full h-full flex flex-col justify-between items-center mb-6 py-5 px-4">
          <div className="h-full w-full">
            <img src={imgsrc} alt="" className="" />
          </div>
          <div>
            <h4 className="text-gray-900 text-sm text-center font-bold mb-3">
              {heading}
            </h4>

            <p className="text-blue-900 text-md  my-4 text-center font-medium ">
              {disc}
            </p>
            <div className="text-center my-5">
              <div>
                <span className="px-4 py-2 bg-indigo-600 text-white text-lg font-bold rounded-md border-2 border-indigo-600 ">
                  {" "}
                  Buy now on
                </span>
                <div className="flex my-5  justify-center">
                  <Link exact="true" target="_blank" to={{ pathname: flink }}>
                    <img
                      alt=""
                      className="w-20 h-12 mx-2"
                      src="https://cdn.worldvectorlogo.com/logos/flipkart.svg"
                    />
                  </Link>
                  <Link target="_blank" exact="true" to={{ pathname: alink }}>
                    <img
                      alt=""
                      className="w-16  h-16 mx-2"
                      src="https://cdn.iconscout.com/icon/free/png-256/amazon-1869030-1583154.png"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Book = () => {
  return (
    <>
      <div className="mx-auto container   py-14 px-6 md:px-20">
        <div className="lg:text-center my-5">
          <h2 className="text-lg text-gray-600 font-semibold tracking-wide uppercase">
            Learn at your Pace
          </h2>
          <p className="my-2 text-2xl  font-bold  text-indigo-600 sm:text-4xl">
            Our Top Selling Books
          </p>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 ">
          {data.map((val) => {
            return (
              <Card
                key={val.id}
                heading={val.heading}
                disc={val.disc}
                imgsrc={val.imgsrc}
                flink={val.flink}
                alink={val.alink}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Book;
