import { SpeakerphoneIcon } from "@heroicons/react/outline";
import React, { useState, useEffect, useContext } from "react";
import MicrostudyApi from "../api/MicrostudyApi";
import { toast } from "react-toastify";
import AuthContext from "../store/authContext";

const Notification = () => {
  const [newNotification, setNewNotification] = useState([]);
  useEffect(() => {
    ViewNotifications();
  }, []);
  const authContext = useContext(AuthContext);
  const ViewNotifications = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(`/user/user-notifications`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setNewNotification(response.data["notification"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <>
      {newNotification?.map((prpos, index) => (
        <div key={index} className="bg-indigo-400 rounded-lg shadow">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center">
              <span className="flex p-2 ">
                <SpeakerphoneIcon
                  className="h-6 w-6 text-gray-900"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 font-semibold text-xl justify-center items-center  text-center text-gray-900">
                {prpos.notificationtext}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
export default Notification;
