import React, { useState } from "react";
const userContext = React.createContext({
  userEmail: "",
  userName: "",
  userPhone: "",
  setUserEmail: () => {},
  setUserName: () => {},
  setUserPhone: () => {},
});
const retrieveStoredData = () => {
  const storedEmail = localStorage.getItem("email");
  const storedName = localStorage.getItem("Name");
  const storedPhone = localStorage.getItem("phone");

  return {
    userName: storedName,
    userEmail: storedEmail,
    userPhone: storedPhone,
  };
};
export const UserContextProvider = (props) => {
  const storedData = retrieveStoredData();
  var initialEmail;
  var initialName;
  var initialPhone;
  if (storedData) {
    initialEmail = storedData.userEmail;
    initialName = storedData.userName;
    initialPhone = storedData.userPhone;
  }
  const [userEmail, setUserEmail] = useState(initialEmail);
  const [userName, setUserName] = useState(initialName);
  const [userPhone, setUserPhone] = useState(initialPhone);

  const userEmailHandler = (userEmail) => {
    setUserEmail(userEmail);
    localStorage.setItem("email", userEmail);
  };
  const userPhoneHandler = (userPhone) => {
    setUserPhone(userPhone);
    localStorage.setItem("phone", userPhone);
  };
  const userNameHandler = (userName) => {
    setUserName(userName);
    localStorage.setItem("Name", userName);
  };
  const contextValue = {
    userEmail: userEmail,
    userName: userName,
    userPhone: userPhone,
    setUserEmail: userEmailHandler,
    setUserName: userNameHandler,
    setUserPhone: userPhoneHandler,
  };
  return (
    <userContext.Provider value={contextValue}>
      {props.children}
    </userContext.Provider>
  );
};

export default userContext;
