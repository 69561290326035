import React from "react";

const Policy = () => {
  return (
    <>
      <div className="m-5 lg:m-10">
        <div className="w-full mx-auto  py-10 lg:py-20">
          <div className="flex">
            <h2 className="text-4xl sm:text-5xl font-black tracking-wide text-center capitalize text-gray-900 mb-10">
              privacy and Policy
            </h2>
          </div>
          <div className="text-lg  text-gray-900">
            <h1 className="my-2 ">Last updated: 06 Dec 2020</h1>
            <h4 className="text-gray-900 font-semibold text-lg my-2">
              Please read these terms and conditions carefully before using Our
              Service.
            </h4>
            <p className="mt-2 ">
              By using, accessing or participating in the Service, you agree to
              the terms of this privacy policy (the "Privacy Policy").
              Capitalized terms not defined in this Privacy Policy have the
              meanings set forth in the Terms and Conditions, located at
              https://microstudy.org. We reserve the right to change our Privacy
              Policy at any time. If we do this, we will post a notice that we
              have made changes to this Privacy Policy on the Website for at
              least 7 days after the changes are posted and will indicate at the
              bottom of the Privacy Policy the date these terms were last
              revised. Any revisions to this Privacy Policy will become
              effective the earlier of (i) the end of the foregoing 7-day period
              or (ii) the first time you access or use the Service after any
              such changes. If you do not agree to abide by this Privacy Policy,
              you are not authorized to use, access or participate in the
              Service.
            </p>
            <h1 className="text-indigo-600 font-semibold text-lg my-2">
              Information We Collect
            </h1>
            <p className="text-gray-700 text-base my-2">
              When you use the Service you provide us with two types of
              information: (i) information you submit via the Service and (ii)
              information regarding your use of the Service collected by us as
              you interact with the Service.
            </p>
            <p className="text-gray-700 text-base my-2">
              When you enter the Website, we collect your browser type and IP
              address. This information is gathered for all Website visitors. In
              addition, we store certain information from your browser using
              "cookies." A cookie is a piece of data stored on the user's
              computer tied to information about the user. We use session ID
              cookies to confirm that users are logged in. If you do not want
              information collected through the use of cookies, there is a
              simple procedure in most browsers that allows you to deny or
              accept the cookie feature; however, you should note that cookies
              may be necessary to provide you certain features (e.g., customized
              delivery of information) available on the Website.
            </p>
            <p className="text-gray-700 text-base my-2">
              Through the registration process you may provide us with your
              name, email address, hometown, and other information that may be
              requested during the registration process. When you use the
              Service you may submit information and content to your profile,
              generate Activity Data through engaging in educational activities
              on the Service, or send messages and otherwise transmit
              information to other users. We store this information so that we
              can provide you the Service and offer personalized features.
            </p>
            <h1 className="text-indigo-600 font-semibold text-lg my-2">
              User of Information Obtained by Microstudy
            </h1>
            <p className="text-gray-700 text-base my-2">
              We may use your contact information to send you notifications
              regarding new services offered by Microstudy and its partners that
              we think you may find valuable. Microstudy may also send you
              service-related announcements from time to time through the
              general operation of the Service. Generally, you may opt out of
              such emails, although Microstudy reserves the right to send you
              notices about your account even if you opt out of all voluntary
              email notifications.
            </p>
            <p className="text-gray-700 text-base my-2">
              Profile information is used by Microstudy primarily to be
              presented back to and edited by you when you access the Service
              and to be presented to other users. In some cases, other users may
              be able to supplement your profile, including by submitting
              comments.
            </p>
            <p className="text-gray-700 text-base my-2">
              Microstudy may use aggregate or anonymous data collected through
              the Service, including Activity Data, for any purpose. This data
              may be used by Microstudy and shared with third parties in any
              manner.
            </p>
            <h1 className="text-indigo-600 font-semibold text-lg my-2">
              Users can request deletion of their data by sending an email to
              <a
                target="_blank"
                href="mailto:support@microstudy.org"
                rel="noopener noreferrer"
              >
                support@microstudy.org
              </a>
            </h1>
            <h3>
              Sharing Your Personally-Identifiable Information with Third
              Parties
            </h3>
            <p className="text-gray-700 text-base my-2">
              Microstudy shares your personally-identifiable information only in
              limited circumstances where Microstudy believes such sharing is
              reasonably necessary to offer the Service, legally required or,
              permitted by you.
            </p>
            <h3 className="text-indigo-600 font-semibold text-lg my-2">
              For example:
            </h3>
            <p className="text-gray-700 text-base my-2">
              We may provide personally-identifiable information to service
              providers who help us bring you the Service, such as hosting the
              Service at a co-location facility or sending email updates. In
              connection with these operations, our service providers may have
              access to personally-identifiable information for use for a
              limited time. Where we utilize service providers for the
              processing of any of personally-identifiable information, we
              implement reasonable contractual protections limiting the use of
              that personally-identifiable information to the provision of
              services to Microstudy.
            </p>
            <p className="text-gray-700 text-base my-2">
              We may be required to disclose personally-identifiable information
              pursuant to lawful requests, such as subpoenas or court orders, or
              in compliance with applicable laws. Additionally, we may share
              account or other personally-identifiable information when we
              believe it is necessary to comply with law, to protect our
              interests or property, to prevent fraud or other illegal activity
              perpetrated through the Service or using the Microstudy name, or
              to prevent imminent harm. This may include sharing
              personally-identifiable information with other companies, lawyers,
              agents or government agencies. If the ownership of all or
              substantially all of the Microstudy business, or individual
              business units or assets owned by Microstudy that are related to
              the Service, were to change, your personally-identifiable
              information may be transferred to the new owner. In any such
              transfer of information, your personally-identifiable information
              would remain subject to this section.
            </p>
            <h1 className="text-indigo-600 font-semibold text-lg my-2">
              Links
            </h1>
            <p className="text-gray-700 text-base my-2">
              The Service may contain links to other websites. We are not
              responsible for the privacy practices of other websites. We
              encourage users to be aware when they leave the Service to read
              the privacy statements of other websites that collect personally
              identifiable information. This Privacy Policy applies solely to
              information collected by Microstudy via the Service. We may also
              integrate with third parties who will interact with you under
              their terms of service.
            </p>
            <h1 className="text-indigo-600 font-semibold text-3xl my-2">
              Contact Us
            </h1>
            <p classname="mt-2  leading-loose">
              If you have any questions about these Terms and Conditions, You
              can contact us:
            </p>
            <div className="  text-gray-700 mt-8">
              <span className="text-center items-center">
                <a
                  target="_blank"
                  href="tel:+917992257628"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg items-center hover:underline font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  +91 799 225 7628
                </a>
                <a
                  target="_blank"
                  href="tel:+918709362272"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg items-center hover:underline font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                  </svg>
                  +91 870 936 2272
                </a>
              </span>
              <span className=" ">
                <a
                  target="_blank"
                  href="mailto:support@microstudy.org"
                  rel="noopener noreferrer"
                  className="flex  px-4 py-2 text-lg hover:underline  items-center font-medium text-gray-900"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  support@microstudy.org
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Policy;
