import React, { useContext, useState, useEffect } from "react";
import MicrostudyApi from "../../api/MicrostudyApi";
import AuthContext from "../../store/authContext";
import { Disclosure } from "@headlessui/react";
import { toast } from "react-toastify";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import ChapterList from "./ChapterList";

const BookList = ({ name, id }) => {
  const authContext = useContext(AuthContext);
  const [bookData, setBookData] = useState([]);
  useEffect(() => {
    getSubjectList();
  }, []);
  const getSubjectList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(`/user/books-fetch/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setBookData(response.data["bookData"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };
  return (
    <>
      <Disclosure as="div" key={id}>
        {({ open }) => (
          <>
            <div className=" flow-root">
              <Disclosure.Button className="w-full flex items-center border-b border-gray-600 justify-between ">
                <span className="font-medium capitalize text-base  text-black">
                  {name}
                </span>
                <span className="flex items-center">
                  {open ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </div>
            <Disclosure.Panel>
              <div className=" w-full flex flex-col justify-center text-gray-900 border-l-4 my-4 rounded-md border-gray-900 ">
                {bookData?.map((bookData) => (
                  <ChapterList
                    key={bookData["id"]}
                    name={bookData["name"]}
                    id={bookData["id"]}
                  />
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default BookList;
