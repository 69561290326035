import Bed_Sheet from "../img/Bed_Sheet.svg";
import Bpsc_sheet from "../img/Bpsc_sheet.svg";
import Deled_sheet from "../img/Deled_sheet.svg";
const data = [
  {
    id: 1,

    heading: "BPSC",
    disc: "Prelims & Mains",
    list: [
      "Syllabus",
      "Solved Previous year Questions",
      "Practice set",
      "Test Series",
      "Current Affairs",
      "Daily News",
      "Exam Notes",
      "Solved Questions",
    ],
    link: "/courses/pricing",
    image: Bpsc_sheet,
  },
  {
    id: 2,

    heading: "JPSC",
    disc: "Prelims & Mains",
    list: [
      "Syllabus",
      "Solved Previous year Questions",
      "Practice set",
      "Test Series",
      "Current Affairs",
      "Daily News",
      "Exam Notes",
      "Solved Questions",
    ],
    link: "/courses/pricing",
    image: "",
  },
  {
    id: 3,

    heading: " D.EL.ED",
    disc: "First year & Second year",
    list: [
      "Syllabus",
      "Solved Previous year Questions",
      "Subjective Syllabus based Question Answer",
      "Notes",
      "Assignments",
    ],
    link: "/courses/pricing",
    image: Deled_sheet,
  },
  {
    id: 4,

    heading: "B.ED",
    disc: "First year & Second year",
    list: [
      "Syllabus",
      "Solved Previous year Questions",
      "Subjective Syllabus based Question Answer",
      "Notes",
      "Assignments",
    ],
    link: "/courses/pricing",
    image: Bed_Sheet,
  },
];
export default data;
