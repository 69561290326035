import React from "react";
import { Link } from "react-router-dom";
import data from "../components/data";

const Card = ({ heading, disc, list, link }) => {
  return (
    <>
      <div className="rounded hover:shadow-xl ">
        <Link exact={true.toString()} to={link}>
          <div className="w-full h-full flex flex-col  shadow-lg bg-indigo-100 rounded-lg mb-6">
            <h4 className="text-gray-900  py-3 rounded-t-lg bg-indigo-400 text-3xl text-center font-bold mb-3">
              {heading}
            </h4>
            <div className="pb-5 pt-3 mx-1 text-gray-900">
              <p className=" text-lg text-center font-semibold mb-3">{disc}</p>
              <div className=" text-lg ">
                <ul>
                  {list?.map((val) => {
                    return (
                      <li
                        key={val}
                        className=" list-none md:list-disc text-left mx-6 list-outside"
                      >
                        {val}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};
const CourseCard = () => {
  return (
    <>
      <div className="mx-auto container py-6 px-6 md:px-20">
        <div className="lg:text-center mt-5 mb-20">
          <h2 className="text-xl text-indigo-600 font-semibold tracking-wide uppercase">
            Learn at your Pace
          </h2>
          <p className="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Online Coaching Lessons For Current Exam Courses.
          </p>
        </div>
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {data?.map((val) => {
            return (
              <Card
                key={val.heading}
                heading={val.heading}
                disc={val.disc}
                list={val.list}
                link={val.link}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
export default CourseCard;
