/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "../components/Footers/Footer";
import CourseCard from "./CourseCard";
import Book from "./Book";
import Faqs from "./Faqs";
import About from "./About";
import Contact from "./Contact";
import heroimg from "../img/undraw_noted_pc-9-f.svg";
const Home = () => {
  return (
    <React.StrictMode>
      <Navbar />
      <div className=" flex bg-white lg:mt-5">
        <div className="max-w-7xl mx-auto">
          <div className="  sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left ">
                <h1 className="text-5xl my-5 tracking-tight font-bold text-gray-900 sm:text-2xl md:text-4xl">
                  <span className="block xl:inline">Welcome to Microstudy</span>
                </h1>
                <span className="my-28  md:ml-48 font-bold  text-indigo-600 italic lg:text-2xl text-lg  px-4 transform sm:translate-x-20 md:translate-x-48 lg:translate-x-72 translate-y-4 ">
                  " In Search of Intelligence."
                </span>
                <p className="my-16 text-gray-500  text-lg sm:max-w-xl sm:mx-auto  lg:mx-0">
                  A one stop destination for all government, academic and
                  competitive exams preparation courses, study materials and
                  Test Series.
                </p>
                <div className=" my-10 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <Link
                      exact="true"
                      to="/Courses/dashboard"
                      className="w-full  flex items-center justify-center px-8 py-4  text-xl font-semibold rounded-md text-black bg-indigo-400 hover:text-black md:py-4 md:text-xl md:px-10">
                      Get started
                    </Link>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      exact="true"
                      target="_blank"
                      to={{
                        pathname:
                          "https://play.google.com/store/apps/details?id=com.microstudypublications.microstudy",
                      }}
                      className="w-full  flex items-center justify-center px-6 py-2.5  text-base font-medium rounded-md text-white bg-gray-700 hover:bg-gray-800 ">
                      <svg viewBox="30 336.7 120.9 129.2" width="30">
                        <path
                          fill="#FFD400"
                          d="M119.2,421.2c15.3-8.4,27-14.8,28-15.3c3.2-1.7,6.5-6.2,0-9.7  c-2.1-1.1-13.4-7.3-28-15.3l-20.1,20.2L119.2,421.2z"
                        />
                        <path
                          fill="#FF3333"
                          d="M99.1,401.1l-64.2,64.7c1.5,0.2,3.2-0.2,5.2-1.3  c4.2-2.3,48.8-26.7,79.1-43.3L99.1,401.1L99.1,401.1z"
                        />
                        <path
                          fill="#48FF48"
                          d="M99.1,401.1l20.1-20.2c0,0-74.6-40.7-79.1-43.1  c-1.7-1-3.6-1.3-5.3-1L99.1,401.1z"
                        />
                        <path
                          fill="#3BCCFF"
                          d="M99.1,401.1l-64.3-64.3c-2.6,0.6-4.8,2.9-4.8,7.6  c0,7.5,0,107.5,0,113.8c0,4.3,1.7,7.4,4.9,7.7L99.1,401.1z"
                        />
                      </svg>
                      <div className="px-2">
                        <div className="text-xs">GET IT ON</div>
                        <div className="text-xl font-semibold ml- -mt-1">
                          Google Play
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2">
          <img
            className="h-full w-full lg:p-20  hidden lg:block right-0 inset-y-0  md:visible"
            src={heroimg}
            alt=""
          />
        </div>
      </div>

      <CourseCard />
      <About />
      <Book />
      <Faqs />
      <Contact />
      <Footer />
    </React.StrictMode>
  );
};
export default Home;
