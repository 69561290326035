import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useRef } from "react";
import logo from "../../img/apple-touch-icon.png";
import MicrostudyApi from "../../api/MicrostudyApi";
import UserContext from "../../store/userContext";
import { toast } from "react-toastify";
const Signup = () => {
  const userContext = useContext(UserContext);
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const phoneInputRef = useRef();
  const passwordInputRef = useRef();
  const history = useHistory();

  const signUpHandler = async (e) => {
    e.preventDefault();

    const name = nameInputRef.current.value;
    const email = emailInputRef.current.value;
    const phone = phoneInputRef.current.value;
    const password = passwordInputRef.current.value;
    try {
      const response = await MicrostudyApi.post(
        "/auth/user/signup",
        {
          name,
          email,
          phone,
          password,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status !== 201 || !response) {
        toast("invalid credentilas!");
      } else {
        userContext.setUserEmail(email);
        history.replace("/auth/signup/otp-verification");
      }
    } catch (error) {
      toast("User Already Exists");
    }
  };
  return (
    <>
      <div className="min-h-screen px-6  flex items-center justify-center ">
        <div className="max-w-md w-full">
          <div>
            <img alt="" className="mx-auto h-16 w-auto" src={logo} />
            <h2 className="mt-6  text-center text-3xl font-bold text-gray-900">
              Create an Account
            </h2>
            <div className="mt-2 px-4 text-center text-sm text-gray-600">
              <span className="px-1 text-lg font-semibold">
                Already have an Account ?
              </span>
              <Link
                exact="true"
                to="/auth/Signin"
                className="font-semibold text-red-500 text-xl hover:text-indigo-500"
              >
                Sign in
              </Link>
            </div>
          </div>
          <form className=" space-y-6" onSubmit={signUpHandler}>
            <div className="rounded-md shadow-sm ">
              <div className="">
                <label htmlFor="name" className=" text-lg font-semibold">
                  Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  ref={nameInputRef}
                  required
                  className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder=" Enter Name"
                />
              </div>
              <div className="mt-2">
                <label htmlFor="email" className=" text-lg font-semibold">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  ref={emailInputRef}
                  required
                  className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email Address"
                />
              </div>
              <div className="mt-2">
                <label htmlFor="phone" className=" text-lg font-semibold">
                  Phone Number
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="tel"
                  ref={phoneInputRef}
                  required
                  className="relative  rounded block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Phone Number"
                />
              </div>
              <div className="mt-2">
                <label htmlFor="password" className="text-lg font-semibold">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  ref={passwordInputRef}
                  required
                  className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder=" Enter Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <span className="ml-2 block text-sm text-gray-900">
                  by Sign up, you agree the{" "}
                  <Link
                    exact
                    className="hover:underline"
                    to="/termsandconditions"
                  >
                    Terms and Conditions.{" "}
                  </Link>
                </span>
              </div>
            </div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-xl font-semibold rounded-md text-white bg-gray-600 hover:bg-indigo-400 hover:text-black "
            >
              Sign up
            </button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Signup;
