import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Fragment } from "react";
import { Transition, Menu } from "@headlessui/react";
import AuthContext from "../store/authContext";
import UserContext from "../store/userContext";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import logo from "../img/apple-touch-icon.png";
import { Disclosure } from "@headlessui/react";
import MicrostudyApi from "../api/MicrostudyApi";
import { toast } from "react-toastify";

export default function Navbar() {
  const authContext = useContext(AuthContext);
  const isSignedin = authContext.isAuthenticated;
  const userContext = useContext(UserContext);
  const [data, setData] = useState([]);
  const [collapseShow, setCollapseShow] = useState("hidden");
  const userEmail = userContext.userEmail;
  const Name = userContext.userName;
  const key = new Date().getMilliseconds();
  var userName = Name;
  const SignoutHandler = (e) => {
    e.preventDefault();
    authContext.Signout();
  };
  useEffect(() => {
    if (isSignedin) {
      enrolledCourses();
    }
  }, []);
  const enrolledCourses = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get("/user/enrolled-courses-fetch", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data["AllEnrolledCourse"];
      setData(data);
    } catch (error) {
      toast(error.message);
    }
  };
  return (
    <>
      <Disclosure as="nav" key={key} className="bg-indigo-400">
        {({ open }) => (
          <>
            <div key={key} className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Link
                      exact={true.toString()}
                      to="/"
                      className=" flex items-center text-3xl px-3 py-2 font-bold rounded-md  active:bg-green-500"
                    >
                      <img alt="" className="mx-auto h-8 w-auto" src={logo} />
                      <span className="text-black px-2">Microstudy</span>
                    </Link>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      <NavLink
                        to="/Courses"
                        className="text-lg font-semibold text-gray-700 hover:text-black hover:font-bold hover:text-2xl"
                      >
                        Our Courses
                      </NavLink>
                      <a
                        href="#about"
                        className="text-lg font-semibold text-gray-700 hover:text-black hover:font-bold hover:text-2xl"
                      >
                        About
                      </a>
                      <a
                        href="#Contact"
                        className="text-lg font-semibold text-gray-700 hover:text-black hover:font-bold hover:text-2xl"
                      >
                        Contact
                      </a>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* Profile dropdown */}
                    {isSignedin && (
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="flex text-sm rounded shadow bg-gray-600">
                            <span className="text-lg font-semibold text-white px-3 py-1 ">
                              My Learning
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-2 px-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {data.length > 0 ? (
                              data.map((props) => (
                                <Menu.Item key={key}>
                                  <Link
                                    to="/courses/dashboard"
                                    className="flex px-2 py-2 text-base mx-2  justify-start items-start hover:bg-purple-400 rounded-md text-center text-gray-900"
                                  >
                                    {props.name}
                                  </Link>
                                </Menu.Item>
                              ))
                            ) : (
                              <>
                                <h1 className="font-xl font-bold text-red-500 my-2">
                                  You don't have any Enroll Courses.
                                </h1>
                                <Link
                                  to="/Courses/pricing"
                                  className="py-1 px-2 mx-auto flex my-2 items-center justify-center  text-center rounded bg-gray-600 focus:ring-2 focus:ring-gray-600 focus:ring-offset-2 text-lg font-bold text-white"
                                >
                                  Enroll now
                                </Link>
                              </>
                            )}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    )}
                    {!isSignedin ? (
                      <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                        <Link
                          exact={true.toString()}
                          to="auth/Signin"
                          className="whitespace-nowrap text-lg font-semibold text-gray-900 border-2 px-5 py-2 rounded-md border-gray-700 hover:text-gray-900"
                        >
                          Sign in
                        </Link>
                        <Link
                          exact={true.toString()}
                          to="auth/Signup"
                          className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-gray-500 hover:border-red-400 rounded-md shadow-sm text-lg font-semibold text-white bg-gray-500 hover:bg-red-400 hover:text-gray-900"
                        >
                          Sign up
                        </Link>
                      </div>
                    ) : (
                      <Menu as="div" className="ml-3 relative">
                        <div>
                          <Menu.Button className="max-w-xs text-black rounded-full flex items-center text-sm">
                            <span className=" font-semibold text-xl capitalize justify-center px-2 items-center ">
                              {userName}
                            </span>
                            <span className=" font-semibold text-black rounded-full  h-10 w-10 flex items-center  focus:ring-gray-800 focus:ring-2  focus:ring-offset-2 justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-15 w-15"
                                viewBox="0 0 20 20"
                                fill="#fffff"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              <p
                                onClick={SignoutHandler}
                                className="flex px-4 py-2 text-xl mx-2 my-2 justify-center items-center hover:bg-red-400 rounded-md text-center font-bold  focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50  text-gray-700"
                              >
                                Sign out
                              </p>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    )}
                  </div>
                </div>
                <div className=" flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className=" inline-flex items-center justify-center  ">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon
                        onClick={() => setCollapseShow("hidden")}
                        className="block h-7 w-7 rounded text-red-500 hover:text-white hover:bg-red-500 outline-none ring-2  ring-red-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <MenuIcon
                        onClick={() =>
                          setCollapseShow("bg-indigo-200 m-2 py-3 px-6")
                        }
                        className="block h-8 w-8 m-1  rounded text-gray-900 hover:text-white hover:bg-gray-700 "
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden py-4 mx-3">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                <NavLink
                  onClick={() => setCollapseShow("hidden")}
                  to="/Courses"
                  className=" text-gray-900 hover:bg-gray-500 hover:text-white
                      block px-3 py-1 rounded-md text-lg font-semibold"
                >
                  Our Courses
                </NavLink>
                <a
                  onClick={() => setCollapseShow("hidden")}
                  href="#about"
                  className=" text-gray-900 hover:bg-gray-500 hover:text-white
                      block px-3 py-1 rounded-md text-lg font-semibold"
                >
                  About
                </a>
                <a
                  onClick={() => setCollapseShow("hidden")}
                  href="#Contact"
                  className=" text-gray-900 hover:bg-gray-500 hover:text-white
                      block px-3 py-1 rounded-md text-lg font-semibold"
                >
                  Contact
                </a>
              </div>
              {!isSignedin ? (
                <div className="space-y-2">
                  <Link
                    to="/auth/signup"
                    className=" flex  mx-2 items-center justify-center px-3 py-1 border border-transparent rounded-md shadow-sm text-lg font-semibold text-white bg-gray-600 hover:bg-red-400"
                  >
                    Sign up
                  </Link>

                  <Link
                    to="/auth/Signin"
                    className=" mx-2 flex items-center justify-center px-3 py-1 border border-gray-700 rounded-md shadow-sm text-lg font-semibold text-gray-800"
                  >
                    Sign in
                  </Link>
                </div>
              ) : (
                <div className="py-3 border-t border-gray-900 sm:flex flex-row space-y-5 sm:justify-between justify-center items-center">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-10 w-10"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <div className="text-lg capitalize font-semibold leading-none text-gray-900">
                        {Name}
                      </div>
                      <div className="text-sm font-semibold leading-none text-gray-900">
                        {userEmail}
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={SignoutHandler}
                    className="block px-3 py-1 rounded-md text-lg font-semibold text-black hover:text-white hover:bg-gray-700 border-gray-900 border-2"
                  >
                    Sign out
                  </button>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
