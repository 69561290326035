import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import MicrostudyApi from "../../api/MicrostudyApi";
import ExamPricingCard from "../../components/Cards/ExampricingCard";
import AuthContext from "../../store/authContext";

const GetExam = ({ name, id }) => {
  const [priceData, setPriceData] = useState([]);
  const authContext = useContext(AuthContext);
  useEffect(() => {
    getExamList();
  }, []);
  const getExamList = async () => {
    try {
      const token = authContext.token;
      const response = await MicrostudyApi.get(`/user/exams-fetch-new/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setPriceData(response.data["examData"]);
      } else {
        toast(response.data.message);
      }
    } catch (error) {
      toast(error.response.data.message);
    }
  };

  return (
    <>
      <div className=" w-full xl:w-12/12 lg:px-6 px-2 py-4 xl:px-16">
        <h1 className="mx-auto text-left font-bold text-5xl word-space">
          {name}
        </h1>
        <div className="lg:flex justify-between space-y-4 lg:space-y-0 my-8 w-full">
          {priceData.length > 0 ? (
            priceData.map((val) => {
              return (
                <ExamPricingCard
                  key={val.id}
                  name={val.name}
                  price={val.price}
                  id={val.id}
                />
              );
            })
          ) : (
            <h1 className="text-lg font-semibold text-center">Comming Soon</h1>
          )}
        </div>
      </div>
    </>
  );
};

export default GetExam;
