import { Link } from "react-router-dom";
import React, { useState } from "react";
const PriceCard = ({ heading, disc, link, image }) => {
  const [show1, setShow1] = useState(false);

  return (
    <>
      <div className="bg-indigo-400  w-full xl:w-11/12 px-6   rounded-md my-5 py-8 xl:px-16 lg:py-16 shadow">
        <div className="lg:flex justify-between w-full">
          <div>
            <div className="flex w-full justify-between lg:justify-start items-center">
              <Link exact="true" to={link}>
                <h1 className="text-color font-black text-3xl lg:text-5xl  lg:mr-8">
                  {heading}
                </h1>
              </Link>

              <button
                className="w-10 h-10 bg-gray-100 focus:outline-none  flex items-center justify-center rounded-full"
                onClick={() => setShow1(!show1)}
              >
                {show1 ? (
                  <svg
                    id="andicators1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={12}
                    viewBox="0 0 18 12"
                    fill="none"
                  >
                    <path
                      d="M9.00002 4.73399L2.40001 11.334L0.514683 9.44865L9.00002 0.963319L17.4854 9.44865L15.6 11.334L9.00002 4.73399Z"
                      fill="#4A5568"
                    />
                  </svg>
                ) : (
                  <svg
                    id="andicators"
                    className
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M16 17.5626L22.6 10.9626L24.4853 12.848L16 21.3333L7.51465 12.848L9.39998 10.9626L16 17.5626Z"
                      fill="#4A5568"
                    />
                  </svg>
                )}
              </button>
            </div>
            <div className="my-3">
              <p className="text-2xl flex justify-start items-start lg:w-10/12">
                <img
                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                  className="mr-4"
                  alt="check-mark"
                />
                {disc.split("&").slice(0, -1).join(" ")}
              </p>
              <p className="text-2xl flex justify-start items-start  lg:w-10/12">
                <img
                  src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                  className="mr-4"
                  alt="check-mark"
                />
                {disc.split("&").slice(-1).join(" ")}
              </p>
            </div>
            {show1 && (
              <div id="listHolder" className="">
                <img
                  className="w-full h-full rounded"
                  alt="Coursesheet"
                  src={image}
                />
              </div>
            )}
          </div>
          <div className="pt-8 lg:pt-0 flex items-center flex-col">
            <Link
              exact="true"
              to={link}
              className="py-4 px-4 xl:px-10 rounded-md shadow bg-white focus:ring-2 focus:ring-600 focus:ring-offset-2 text-xl font-bold text-indigo-600 mt-7"
            >
              More Info
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default PriceCard;
