import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "../components/Navbars/AuthNavbar";
import FooterSmall from "../components/Footers/FooterAdmin";
import UpdatePassword from "./auth/UpdatePassword";
// views
import Signin from "./auth/Signin";
import Signup from "./auth/Signup";
import ResetPassword from "./auth/ResetPassword";
import SignupOtpVerification from "./auth/SignupOtpVerification";

const Auth = () => {
  return (
    <React.StrictMode>
      <Navbar transparent />
      <div>
        <div className="relative bg-gray-100  min-h-screen">
          <Switch>
            <Route
              path="/auth/user_update_password"
              exact
              component={UpdatePassword}
            />
            <Route
              path="/auth/user_reset_password"
              exact
              component={ResetPassword}
            />
            <Route path="/auth/Signin" exact component={Signin} />
            <Route path="/auth/Signup" exact component={Signup} />
            <Route
              path="/auth/signup/otp-verification"
              exact
              component={SignupOtpVerification}
            />
            <Redirect from="/auth" to="/auth/Signin" />
          </Switch>
          <FooterSmall absolute />
        </div>
      </div>
    </React.StrictMode>
  );
};
export default Auth;
